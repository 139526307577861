// Libraries
// @ts-expect-error TS(2307): Cannot find module 'components' or its correspondi... Remove this comment to see the full error message
import {BackButton} from 'components';
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {JobConfirmCrewForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation} from '@supermove/hooks';
import {DeviceInfo} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';

// App
// @ts-expect-error TS(2307): Cannot find module 'config' or its corresponding t... Remove this comment to see the full error message
import {AppInfo} from 'config';
import {Notification, PageLoadingIndicator} from 'modules/App/components';
import {CrewJobProgress, CrewJobPage} from 'modules/Job/Crew/components';
import {CreateJobUsersModal, JobUserFormItem} from 'modules/Job/User/Confirm/components';
import {useJobConfirmCrewMutation} from 'modules/Job/User/Confirm/logic';
import {ConfirmCrewForJobUserErrorModal, ConfirmJobErrorModal} from 'modules/Job/User/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
`;

const Info = Styled.View`
  margin-horizontal: 40px;
`;

const Title = Styled.H2`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Subtitle = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const JobUsers = Styled.View`
  margin-vertical: 20px;
  margin-horizontal: 40px;
`;

const Footer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const Group = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const deleteJobUser = ({form, index: removeIndex}: any) => {
  const {jobUserForms} = form.values.jobConfirmCrewForm;
  const newJobUserForms = jobUserForms.filter(
    (jobUserForm: any, index: any) => index !== removeIndex,
  );

  // Set the new jobUserForms on the main form.
  form.setFieldValue(`jobConfirmCrewForm.jobUserForms`, newJobUserForms);
};

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 20px;
  background-color: ${colors.gray.background};
  align-items: center;
`;

const Cell = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const CellText = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const EditSpace = Styled.View`
  width: 40px;
`;

const JobUsersHeader = ({organization}: any) => {
  return (
    <Header>
      <Cell style={{flex: 2}}>
        <CellText>Name</CellText>
      </Cell>
      <Cell style={{flex: 4}}>
        <Cell>
          <CellText>Position</CellText>
        </Cell>
        {organization.features.isEnabledJobUserShowBranchCode && (
          <Cell>
            <CellText>Branch Code</CellText>
          </Cell>
        )}
        <EditSpace />
      </Cell>
      <Space width={40} />
    </Header>
  );
};

const ConfirmUsersJobPageContent = ({data, handleOpenUser, handleOpenNoUsers}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const {navigator, params} = useNavigation();
  const {form, submitting, handleSubmit} = useJobConfirmCrewMutation({
    jobConfirmCrewForm: JobConfirmCrewForm.newFromJob(data.job, {
      userId: data.viewer.id,
      appInstallId: _.get(data, 'device.appInstall.id'),
    }),
    onSuccess: ({job}: any) => {
      navigator.navigate(params.success, {uuid: params.uuid});
    },
    onError: (errors: any) => {
      const firstError = _.first(errors);
      switch ((firstError as any).field) {
        case 'job_confirm_crew_form.user_id':
          return handleOpenUser();
        case 'job_confirm_crew_form.job_user_forms':
          return handleOpenNoUsers();
        default:
          return;
      }
    },
  });

  return (
    <Container>
      <ScrollView style={{flex: 1}}>
        <CrewJobProgress selected={'select-user'} items={CrewJobProgress.items.pre} />
        <Content>
          <Info>
            <Title>Select Crew</Title>
            <Subtitle>
              These members are assigned to this job. Tap "Add Crew" to modify this list.
            </Subtitle>
          </Info>
          <JobUsers>
            <FlatList
              listKey={'confirm-users-job-page-job-users-list'}
              data={form.values.jobConfirmCrewForm.jobUserForms as any[]}
              keyExtractor={(jobUserForm, index) => String(index)}
              extraData={form}
              renderItem={({item: jobUserForm, index}) => {
                const field = `jobConfirmCrewForm.jobUserForms.${index}`;

                return (
                  <JobUserFormItem
                    field={field}
                    form={form}
                    jobUserForm={jobUserForm}
                    organization={data.job.organization}
                    onDelete={() => deleteJobUser({form, index})}
                  />
                );
              }}
              ListHeaderComponent={() => <JobUsersHeader organization={data.job.organization} />}
              ListEmptyComponent={() => (
                <Notification
                  color={colors.orange.status}
                  text={
                    `No crew members have been assigned to ` +
                    `this job. Add crew by tapping "Add Crew" below.`
                  }
                  style={{
                    marginVertical: 20,
                  }}
                />
              )}
            />
          </JobUsers>
        </Content>
      </ScrollView>
      <Footer>
        <Group>
          <BackButton />
          <ButtonSpace />
          <Button color={colors.blue.interactive} onPress={handleOpen}>
            <Text>Add Crew</Text>
          </Button>
        </Group>
        <Button loading={submitting} color={colors.blue.interactive} onPress={handleSubmit}>
          <Text>Confirm</Text>
        </Button>
      </Footer>
      <CreateJobUsersModal
        jobId={data.job.id}
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={(jobUserForms: any) => {
          // Merge the new jobUserForms with the existing ones.
          const newJobUserForms = [...form.values.jobConfirmCrewForm.jobUserForms, ...jobUserForms];
          form.setFieldValue('jobConfirmCrewForm.jobUserForms', newJobUserForms);
          handleClose();
        }}
      />
    </Container>
  );
};

const ConfirmUsersJobPage = () => (
  <CrewJobPage
    fetchPolicy={'network-only'}
    getVariables={({params}: any) => ({
      uuid: params.uuid,
      appName: 'Crew',
      appPlatform: DeviceInfo.getPlatform(),
      releaseBuild: AppInfo.getBuildNumber(),
      deviceUniqueId: DeviceInfo.getUniqueId(),
    })}
    query={ConfirmUsersJobPage.query}
  >
    {({loading, data}: any) => (
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <ConfirmCrewForJobUserErrorModal
            name={data.viewer.firstName}
            // @ts-expect-error TS(7031): Binding element 'handleOpenUser' implicitly has an... Remove this comment to see the full error message
            trigger={({handleOpen: handleOpenUser}) => (
              <ConfirmJobErrorModal
                // @ts-expect-error TS(7031): Binding element 'handleOpenNoUsers' implicitly has... Remove this comment to see the full error message
                trigger={({handleOpen: handleOpenNoUsers}) => (
                  <ConfirmUsersJobPageContent
                    data={data}
                    handleOpenUser={handleOpenUser}
                    handleOpenNoUsers={handleOpenNoUsers}
                  />
                )}
              />
            )}
          />
        )}
      </Loading>
    )}
  </CrewJobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmUsersJobPage.query = gql`
  ${JobConfirmCrewForm.newFromJob.fragment}
  ${CrewJobPage.fragment}
  ${JobUserFormItem.fragment}

  query ConfirmUsersJobPage(
    $uuid: String!,
    $appName: String!,
    $appPlatform: String!,
    $releaseBuild: Int!,
    $deviceUniqueId: String!,
  ) {
    ${gql.query}
    viewer {
      id
      firstName
    }
    device(uniqueId: $deviceUniqueId) {
      id
      appInstall(
        appName: $appName,
        appPlatform: $appPlatform,
        releaseBuild: $releaseBuild,
      ) {
        id
      }
    }
    job(uuid: $uuid) {
      id
      organization {
        id
        features {
          isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
        }
        ...JobUserFormItem
      }
      ...JobConfirmCrewForm_newFromJob
      ...CrewJobPage
    }
  }
`;

export default ConfirmUsersJobPage;
