// Libraries
import React from 'react';

// App
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// Components
import {Notification} from 'modules/App/components';

import CustomerJobDocumentItem from './CustomerJobDocumentItem';

const Document = Styled.View`
`;

const CustomerJobDocumentsList = ({job}: {job: JobModel}) => (
  <FlatList
    listKey={'customer-job-documents-list'}
    data={job.filteredDocuments}
    keyExtractor={(document) => document.id}
    renderItem={({item: document, index}) => (
      <Document>
        <CustomerJobDocumentItem
          isFirst={index === 0}
          isLast={index === job.filteredDocuments.length - 1}
          document={document}
        />
      </Document>
    )}
    ListEmptyComponent={() => (
      <Notification
        color={colors.orange.status}
        text={'This job does not currently have any additional documents.'}
      />
    )}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerJobDocumentsList.fragment = gql`
  ${CustomerJobDocumentItem.fragment}

  fragment CustomerJobDocumentsList on Job {
    id
    filteredDocuments(step: $step) {
      id
      uuid
      template
      ...CustomerJobDocumentItem
    }
  }
`;

export default CustomerJobDocumentsList;
