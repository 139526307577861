// Libraries
import React from 'react';

// Components
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {DocumentModel} from '@supermove/models';

import CrewPrepareDocumentSignatureItem from './CrewPrepareDocumentSignatureItem';

const DocumentSignature = Styled.View`
  margin-horizontal: 40px;
`;

const Cell = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Footer = Styled.View`
  height: 50px;
`;

const CrewPrepareDocumentSignaturesList = ({
  documentSignatures,
  document,
  refetch,
}: {
  documentSignatures: any[];
  document: DocumentModel;
  refetch: () => void;
}) => (
  <FlatList
    listKey={'crew-prepare-document-signatures-list'}
    data={documentSignatures}
    keyExtractor={(documentSignature) => documentSignature.id}
    renderItem={({item: documentSignature, index}) => (
      <DocumentSignature>
        <CrewPrepareDocumentSignatureItem
          isFirst={index === 0}
          isLast={index === documentSignatures.length - 1}
          documentSignature={documentSignature}
          document={document}
          refetch={refetch}
        />
      </DocumentSignature>
    )}
    CellRendererComponent={({
      index,
      children,
      // @ts-expect-error TS(2769): No overload matches this call.
    }: any) => <Cell index={index} children={children} />}
    ListFooterComponent={() => <Footer />}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPrepareDocumentSignaturesList.fragment = gql`
  ${CrewPrepareDocumentSignatureItem.fragment}

  fragment CrewPrepareDocumentSignaturesList_Document on Document {
    id
    ...CrewPrepareDocumentSignatureItem_Document
  }

  fragment CrewPrepareDocumentSignaturesList on DocumentSignature {
    id
    ...CrewPrepareDocumentSignatureItem
  }
`;

export default CrewPrepareDocumentSignaturesList;
