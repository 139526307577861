/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {AttachmentModel} from '@supermove/models';

// App
import JobAttachmentItem from 'modules/Job/Attachment/components/JobAttachmentItem';

const JobAttachmentsList = ({
  uuid,
  attachments,
  onPress,
  onDelete,
}: {
  uuid: string;
  attachments: AttachmentModel[];
  onPress: (args: {jobUuid: string; attachmentUuid: string}) => void;
  onDelete: () => void;
}) => {
  return (
    <React.Fragment>
      <FlatList
        listKey={'job-attachments-list'}
        horizontal
        data={attachments}
        keyExtractor={(attachment) => attachment.id}
        renderItem={({item: attachment, index}) => (
          <React.Fragment key={attachment.id}>
            <JobAttachmentItem
              attachment={attachment}
              onPress={() => onPress({jobUuid: uuid, attachmentUuid: attachment.uuid})}
              onDelete={onDelete}
              index={index}
            />
            <Space width={20} />
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobAttachmentsList.fragment = gql`
  ${JobAttachmentItem.fragment}

  fragment JobAttachmentsList on Attachment {
    id
    uuid
    ...JobAttachmentItem
  }
`;

export default JobAttachmentsList;
