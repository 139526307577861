// Libraries
import React from 'react';

// Supermove
import {FlatList, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigation} from '@supermove/hooks';
import {JobModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Navigation from 'core/Navigation';
import Notification from 'modules/App/components/Notification';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CustomerJobPageV2 from 'modules/Job/Customer/components/CustomerJobPageV2';
import CustomerJobProgress from 'modules/Job/Customer/components/CustomerJobProgress';

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 40px;
`;

const Info = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Body1}
  font-size: 32px;
  line-height: 44px;
`;

const Text = Styled.Text`
  ${Typography.Body1}
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
  padding-horizontal: 20px;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const DocumentListItemContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 15px;
  padding-horizontal: 10px;
`;

const DocumentDisplayNameContainer = Styled.View`
  flex: 1;
`;

const DocumentsListItem = ({document, index}: any) => {
  return (
    <React.Fragment>
      {index === 0 && <Line />}
      <DocumentListItemContainer>
        <DocumentDisplayNameContainer>
          <Text>{`${index + 1}. ${document.displayName}`}</Text>
        </DocumentDisplayNameContainer>
      </DocumentListItemContainer>
      <Line />
    </React.Fragment>
  );
};

const DocumentsList = ({job}: {job: JobModel}) => {
  return (
    <FlatList
      listKey={'documents-list'}
      data={job.documentsV2ForStep}
      keyExtractor={(document) => document.id}
      renderItem={({item: document, index}) => (
        <DocumentsListItem document={document} index={index} />
      )}
      ListEmptyComponent={() => (
        <Notification
          color={colors.orange.status}
          text={'This job does not currently have any additional documents.'}
        />
      )}
    />
  );
};

const Footer = ({job}: any) => {
  const {navigator} = useNavigation();

  return (
    <CustomerJobPageV2.Footer style={{flexDirection: 'row', alignItems: 'center', padding: 10}}>
      <Button color={colors.gray.secondary} onPress={() => navigator.goBack()}>
        <ButtonText>Back</ButtonText>
      </Button>
      <Space style={{flex: 1}} />
      <Button
        color={colors.blue.interactive}
        onPress={() => {
          Navigation.navigateToNextIncompleteDocumentForAfterTimesheet({
            navigator,
            job,
          });
        }}
      >
        <ButtonText>Next</ButtonText>
      </Button>
    </CustomerJobPageV2.Footer>
  );
};

const AfterTimesheetListDocumentCustomerJobPageContent = ({job}: any) => {
  return (
    <Container>
      <CustomerJobProgress selected={'agreements'} items={CustomerJobProgress.items.finish} />
      <ScrollView style={{flex: 1}}>
        <Content>
          <Info>
            <Title>{`Hi, ${job.customer.fullName}.`}</Title>
            <Text>{`The next steps will guide you through these documents.`}</Text>
          </Info>
          <Space height={20} />
          <DocumentsList job={job} />
        </Content>
      </ScrollView>
      <Footer job={job} />
    </Container>
  );
};

const AfterTimesheetListDocumentCustomerJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(AfterTimesheetListDocumentCustomerJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  return (
    <CustomerJobPageV2 refetch={refetch}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return <AfterTimesheetListDocumentCustomerJobPageContent job={data.job} />;
        }}
      </Loading>
    </CustomerJobPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AfterTimesheetListDocumentCustomerJobPage.query = gql`
  ${Navigation.navigateToNextIncompleteDocumentForAfterTimesheet.fragment}

  query AfterTimesheetListDocumentCustomerJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      customer {
        id
        fullName
      }
      documentsV2ForStep(step: "AFTER_TIMESHEET") {
        id
        displayName
      }
      ...Navigation_navigateToNextIncompleteDocumentForAfterTimesheet
    }
  }
`;

export default AfterTimesheetListDocumentCustomerJobPage;
