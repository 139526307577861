// Libraries
import React from 'react';

// Components
import {FlatList} from '@supermove/components';
import {TruckModel} from '@supermove/models';

const OrganizationTrucksList = ({
  trucks,
  extraData,
  renderTruck,
}: {
  trucks: TruckModel[];
  extraData: any;
  renderTruck: ({truck, index}: {truck: TruckModel; index: number}) => React.ReactElement;
}) => (
  <FlatList
    listKey={'organization-trucks-list'}
    data={trucks}
    keyExtractor={(truck) => truck.id}
    extraData={extraData}
    renderItem={({item: truck, index}) => renderTruck({truck, index})}
  />
);

export default OrganizationTrucksList;
