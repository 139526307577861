// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {JobModel} from '@supermove/models';

// App
import JobUserTimesheetFormItem from './JobUserTimesheetFormItem';

const JobUserItem = Styled.View`
`;

const Footer = Styled.View`
  height: 60px;
`;

// TODO(mark): Might find a better way to match up rendering data with form data.
const findJobUser = ({job, jobUserTimesheetForm}: any) => {
  return _.find(job.confirmedJobUsers, (jobUser) => jobUserTimesheetForm.jobUserId === jobUser.id);
};

const JobUserTimesheetFormsList = ({
  job,
  jobUserTimesheetForms,
  form,
  header,
}: {
  job: JobModel;
  jobUserTimesheetForms: any[];
  form: any;
  header: () => React.ReactElement;
}) => (
  <ScrollView horizontal>
    <FlatList
      listKey={'job-user-timesheet-forms-list'}
      data={jobUserTimesheetForms}
      keyExtractor={(jobUserTimesheetForm) => jobUserTimesheetForm.jobUserId}
      extraData={form}
      renderItem={({item: jobUserTimesheetForm, index}) => (
        <JobUserItem>
          <JobUserTimesheetFormItem
            isFirst={index === 0}
            isLast={index === jobUserTimesheetForms.length - 1}
            index={index}
            jobUser={findJobUser({job, jobUserTimesheetForm})}
            jobUserTimesheetForm={jobUserTimesheetForm}
            form={form}
          />
        </JobUserItem>
      )}
      ListHeaderComponent={header}
      ListFooterComponent={Footer}
    />
  </ScrollView>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserTimesheetFormsList.fragment = gql`
  ${JobUserTimesheetFormItem.fragment}

  fragment JobUserTimesheetFormsList on Job {
    id
    confirmedJobUsers {
      id
      ...JobUserTimesheetFormItem
    }
  }
`;

export default JobUserTimesheetFormsList;
