// Libraries
import React from 'react';

// Components
import {FlatList} from '@supermove/components';
import {UserModel} from '@supermove/models';

type Props = {
  renderUser: ({user}: {user: UserModel}) => React.ReactElement;
  users: UserModel[];
  extraData?: any;
  renderEmpty?: () => React.ReactElement | null;
};

const OrganizationUsersGrid = ({users, extraData, renderUser, renderEmpty = () => null}: Props) => (
  <FlatList
    listKey={'organization-users-grid'}
    data={users}
    keyExtractor={(user) => user.id}
    numColumns={6}
    extraData={extraData}
    renderItem={({item: user}) => renderUser({user})}
    ListEmptyComponent={renderEmpty}
  />
);

export default OrganizationUsersGrid;
