// Libraries
import React from 'react';

// Supermove
import {KeyboardView, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import BillTipForm from '@shared/modules/Billing/forms/BillTipForm';
import useCreateBillTipMutation from '@shared/modules/Billing/hooks/useCreateBillTipMutation';
import useFinishJobPostMovePaymentsMutation from '@shared/modules/Job/hooks/useFinishJobPostMovePaymentsMutation';
import PayEngineCreditCardInput from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import useSavePayEngineCreditCard from '@shared/modules/Payment/hooks/useSavePayEngineCreditCard';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import Navigation from 'core/Navigation';
import PageFooter from 'modules/App/components/PageFooter';
import PageV2 from 'modules/App/components/PageV2';
import PaymentErrorModal from 'modules/Project/Billing/Payment/New/components/PaymentErrorModal';
import PaymentAmountSection from 'modules/Project/Billing/Payment/components/PaymentAmountSection';

const Container = Styled.View`
  flex: 1;
`;

const TitleContainer = Styled.View`
  align-items: center;
`;

const InputContainer = Styled.View`
  padding-horizontal: 40px;
`;

const Button = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const ProjectBillingPayEngineSaveCardPaymentSection = ({job}: any) => {
  const {params} = useNavigation();
  const isMainFlow = Navigation.isMainFlowPayment({params});

  const [errorMessage, setErrorMessage] = React.useState(null);
  const errorModal = useModal();

  const [isSaveSuccess, setIsSaveSuccess] = React.useState(false);
  const [isFormLoaded, setIsFormLoaded] = React.useState(false);
  const onLoad = React.useCallback(() => {
    setIsFormLoaded(true);
  }, [setIsFormLoaded]);

  // @ts-expect-error TS(2345): Argument of type '{ clientId: any; onSuccess: (res... Remove this comment to see the full error message
  const savePayEngineCreditCard = useSavePayEngineCreditCard({
    clientId: job.project.billingClient.id,
    onSuccess: (response) => {},
    onError: (error) => {
      console.error(`Error executing SavePayengineCreditCardMutation: `, error);
      // @ts-expect-error TS(2345): Argument of type '"Could not save credit card."' i... Remove this comment to see the full error message
      setErrorMessage(`Could not save credit card.`);
      errorModal.handleOpen();
    },
    onTokenizeError: (error) => {
      console.error(`Error tokenizing credit card: `, error);
      // @ts-expect-error TS(2345): Argument of type '"Invalid credit card information... Remove this comment to see the full error message
      setErrorMessage(`Invalid credit card information.`);
      errorModal.handleOpen();
    },
  });

  const createBillTipMutation = useCreateBillTipMutation({
    billTipForm: BillTipForm.new({
      billId: job.project.currentPrimaryBill.id,
      jobId: job.id,
      name: Job.getTipName(job),
      amount: params.tipAmount,
    }),
    onSuccess: () => {},
    onError: (error: any) => {
      console.error(`Error executing CreateBillTipMutation: `, error);
      // @ts-expect-error TS(2345): Argument of type '"Could not save credit card."' i... Remove this comment to see the full error message
      setErrorMessage(`Could not save credit card.`);
      errorModal.handleOpen();
    },
  });

  const finishJobPostMovePaymentsMutation = useFinishJobPostMovePaymentsMutation({
    jobId: job.id,
    paymentMethod: PaymentMethodKind.PAYENGINE_SAVE_CARD,
    onSuccess: () => {},
    onError: (error: any) => {
      console.error(`Error executing FinishJobPostMovePaymentsMutation: `, error);
      // @ts-expect-error TS(2345): Argument of type '"Could not save credit card."' i... Remove this comment to see the full error message
      setErrorMessage(`Could not save credit card.`);
      errorModal.handleOpen();
    },
  });

  const onPress = async () => {
    try {
      // Skip tokenization & SavePayengineCreditCardMutation if it already succeeded. We shouldn't
      // re-tokenize the card if the subsequent mutations fail, since that will result in duplicate
      // saved cards for this client.
      if (!isSaveSuccess) {
        const saveCardResponse = await savePayEngineCreditCard.handleSubmit();
        const cardId = saveCardResponse?.id;
        if ((saveCardResponse as any)?.errors || !cardId) {
          return;
        }
        setIsSaveSuccess(true);
      }

      const createBillTipResponse = await createBillTipMutation.handleSubmit();
      const createBillTipErrors =
        createBillTipResponse?.errors || createBillTipResponse?.data?.response?.errors;
      if (!createBillTipResponse || createBillTipErrors) {
        return;
      }

      const finishJobPostMovePaymentsResponse =
        await finishJobPostMovePaymentsMutation.handleSubmit();
      const finishJobPostMovePaymentsErrors =
        finishJobPostMovePaymentsResponse?.errors ||
        finishJobPostMovePaymentsResponse?.data?.response?.errors;
      if (!finishJobPostMovePaymentsResponse || finishJobPostMovePaymentsErrors) {
        return;
      }

      setErrorMessage(null);
    } catch (error) {
      console.error('Unhandled error: ', error);
      // @ts-expect-error TS(2345): Argument of type '"Something went wrong."' is not ... Remove this comment to see the full error message
      setErrorMessage('Something went wrong.');
      errorModal.handleOpen();
    }
  };

  const isLoading =
    savePayEngineCreditCard.submitting ||
    finishJobPostMovePaymentsMutation.submitting ||
    createBillTipMutation.submitting;

  return (
    <Container>
      <Space height={16} />
      <TitleContainer>
        <PageV2.Title>Please enter your card information.</PageV2.Title>
        <PageV2.Subtitle>You will NOT be charged right now.</PageV2.Subtitle>
        <Space height={20} />
        <PaymentAmountSection
          title={'Payment Amount:'}
          amount={Currency.display(params.paymentAmount)}
        />
      </TitleContainer>
      <ScrollView style={{flex: 1}}>
        <Space height={16} />
        <KeyboardView>
          <InputContainer>
            <PayEngineCreditCardInput
              setCreditCardClient={savePayEngineCreditCard.setCreditCardClient}
              mode={job.organization.mode}
              onLoad={onLoad}
            />
          </InputContainer>
        </KeyboardView>
        <Space height={16} />
      </ScrollView>
      <PageFooter>
        <PageFooter.Row style={{justifyContent: 'space-between'}}>
          {isMainFlow ? <PageFooter.BackButton /> : <Space />}
          <Button
            disabled={!isFormLoaded}
            loading={isLoading}
            color={colors.blue.interactive}
            onPress={onPress}
          >
            <Text>Submit</Text>
          </Button>
        </PageFooter.Row>
      </PageFooter>
      <PaymentErrorModal
        isOpen={errorModal.isOpen}
        handleClose={errorModal.handleClose}
        title={errorMessage}
        subtitle={
          'We were unable to save this credit card. ' +
          'Please call us at (415) 991-1969 to fix this as soon as possible.'
        }
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillingPayEngineSaveCardPaymentSection.fragment = gql`
  ${Job.getFullName.fragment}
  ${Job.getTipName.fragment}

  fragment ProjectBillingPayEngineSaveCardPaymentSection on Job {
    id
    project {
      id
      currentPrimaryBill {
        id
      }
      billingClient {
        id
      }
    }
    organization {
      id
      mode
    }
    ...Job_getFullName
    ...Job_getTipName
  }
`;

export default ProjectBillingPayEngineSaveCardPaymentSection;
