// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';
import JobStatusBadge from 'modules/Job/components/JobStatusBadge';

const Container = Styled.View<{isSelected: boolean}>`
  flex-direction: row;
  background-color: ${(props) => ((props as any).isSelected ? colors.blue.interactive : colors.white)};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Info = Styled.View`
  flex: 1;
  padding-right: 10px;
`;

const ProjectTypeColorBar = Styled.View<{color: string}>`
  width: 6px;
  height: 100%;
  background-color: ${({color}) => color};
`;

const ProjectTypeName = Styled.Text<{vars: {isCancelled: boolean; color: string}}>`
  ${Typography.Label2}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : vars.color)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const ProjectName = Styled.Text<{vars: {isCancelled: boolean}}>`
  ${Typography.Body3}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.gray.primary)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const JobName = Styled.Text<{vars: {isCancelled: boolean}}>`
  ${Typography.Body1}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.gray.primary)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const TrainingName = Styled.Text<{vars: {isCancelled: boolean}}>`
  ${Typography.Body1}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.purple.hover)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const Description = Styled.Text<{vars: {isCancelled: boolean}}>`
  ${Typography.Body3}
  color: ${({vars}) => (vars.isCancelled ? colors.gray.tertiary : colors.gray.secondary)};
  text-decoration-line: ${({vars}) => (vars.isCancelled ? 'line-through' : 'none')};
`;

const getJobLocationAndMoveSizeText = (job: any) => {
  const locationText = Job.getStartLocationTitle(job);
  return `${locationText ? `${locationText} ` : ``}${job.moveSize ? `- ${job.moveSize}` : ``}`;
};

const getJobDetails = (job: any) => {
  const showDate = Job.getIsEstimatedRange(job) || Job.getIsChildJob(job);
  return `${
    showDate ? `${Job.getDisplayDate(job, 'MM/DD', 'TBD')} ` : ''
  }${getJobLocationAndMoveSizeText(job)}`;
};

const ConfirmDateModal = ({jobUuid, jobsListDate, isOpen, handleClose, refetch}: any) => {
  const {navigator} = useNavigation();
  const otherDate = Datetime.convertToDisplayDate(jobsListDate);
  return (
    <MessageModal
      isOpen={isOpen}
      title={'Start job?'}
      message={`This is a job for ${otherDate}. Would you like to continue?`}
      handlePressOutside={handleClose}
      handlePrimaryAction={() => navigator.navigate('ShowJob', {uuid: jobUuid})}
      handleSecondaryAction={() => {
        refetch();
        handleClose();
      }}
      primaryActionText={'Yes, start this job'}
      secondaryActionText={'Refresh jobs'}
    />
  );
};

const JobItem = ({isSelected, job, jobsListDate, refetch}: any) => {
  const {navigator} = useNavigation();
  const confirmDateModal = useModal({name: 'Confirm crew app jobs list date modal'});
  const isCancelled = job.isCancelled || job.project.isCancelled;

  return (
    <Container isSelected={isSelected}>
      <Touchable
        disabled={isCancelled}
        onPress={() => {
          const currentDate = Datetime.toDate(Datetime.today);
          if (jobsListDate !== currentDate) {
            return confirmDateModal.handleOpen();
          }
          navigator.navigate('ShowJob', {uuid: job.uuid});
        }}
      >
        <Row>
          <ProjectTypeColorBar color={job.project.projectType.color} />
          <Space width={18} />
          <Info>
            <Space height={9} />
            <ProjectTypeName
              numberOfLines={2}
              vars={{isCancelled, color: job.project.projectType.color}}
            >
              {job.project.projectType.name}
            </ProjectTypeName>
            <Space height={1} />
            {job.organization.features.isEnabledProjectIdentifierField && (
              <React.Fragment>
                <ProjectName numberOfLines={1} vars={{isCancelled}}>
                  {Project.getDisplayText(job.project)}
                </ProjectName>
                <Space height={1} />
              </React.Fragment>
            )}
            <JobName numberOfLines={2} vars={{isCancelled}}>
              {Job.getJobNameForCrew(job)}
              {job.isTest && <TrainingName vars={{isCancelled}}>{` (Training)`}</TrainingName>}
            </JobName>
            <Space height={1} />
            <Description numberOfLines={1} vars={{isCancelled}}>
              {getJobDetails(job)}
            </Description>
            <Space height={9} />
          </Info>
          <JobStatusBadge job={job} />
          <Space width={18} />
        </Row>
      </Touchable>
      <ConfirmDateModal
        jobUuid={job.uuid}
        jobsListDate={jobsListDate}
        isOpen={confirmDateModal.isOpen}
        handleClose={confirmDateModal.handleClose}
        refetch={refetch}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobItem.fragment = gql`
  ${Job.getFullName.fragment}
  ${Job.getDisplayDate.fragment}
  ${Job.getIsEstimatedRange.fragment}
  ${Job.getIsChildJob.fragment}
  ${Job.getJobNameForCrew.fragment}
  ${Job.getStartLocationTitle.fragment}
  ${Project.getDisplayText.fragment}
  ${JobStatusBadge.fragment}

  fragment JobItem on Job {
    id
    uuid
    isCancelled
    isTest
    moveSize
    organization {
      id
      features {
        isEnabledProjectIdentifierField: isEnabled(feature: "PROJECT_IDENTIFIER_FIELD")
      }
    }
    project {
      id
      isCancelled
      projectType {
        id
        name
        color
      }
      ...Project_getDisplayText
    }
    ...Job_getDisplayDate
    ...Job_getFullName
    ...Job_getIsEstimatedRange
    ...Job_getIsChildJob
    ...Job_getJobNameForCrew
    ...Job_getStartLocationTitle
    ...JobStatusBadge
  }
`;

export default JobItem;
