// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App
import Navigation from 'core/Navigation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBillingPayEngineCreditCardPaymentSection from 'modules/Project/Billing/Payment/New/Method/PayEngineCreditCard/components/ProjectBillingPayEngineCreditCardPaymentSection';
import ProjectBillingHeader from 'modules/Project/Billing/components/ProjectBillingHeader';

const Container = Styled.View`
  flex: 1;
`;

const Content = ({job, paymentMethod}: any) => {
  const {params} = useNavigation();
  const isMainFlow = Navigation.isMainFlowPayment({params});
  return (
    <Container>
      <ProjectBillingHeader
        leftButtonType={isMainFlow ? undefined : 'BACK'}
        title={Project.getName(job.project)}
        subtitle={Job.getFullName(job)}
      />
      <Container>
        <ProjectBillingPayEngineCreditCardPaymentSection
          job={job}
          paymentMethod={paymentMethod}
          displaySavedCards={job.organization.settings.isCrewChargeSavedCardEnabled}
        />
      </Container>
    </Container>
  );
};

const PayEngineCreditCardMethodNewPaymentBillingProjectJobPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(
    PayEngineCreditCardMethodNewPaymentBillingProjectJobPage.query,
    {
      fetchPolicy: 'network-only',
      variables: {
        jobUuid: params.jobUuid,
        paymentMethodUuid: params.paymentMethodUuid || '',
      },
    },
  );

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <Content job={data.job} refetch={refetch} paymentMethod={data.paymentMethodByUuid} />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayEngineCreditCardMethodNewPaymentBillingProjectJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${Job.getPaymentName.fragment}
  ${Project.getName.fragment}
  ${ProjectBillingPayEngineCreditCardPaymentSection.fragment}

  query PayEngineCreditCardMethodNewPaymentBillingProjectJobPage($jobUuid: String!, $paymentMethodUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      project {
        id
        ...Project_getName
      }
      organization {
        id
        settings {
          id
          isCrewChargeSavedCardEnabled
        }
      }
      ...Job_getFullName
      ...Job_getPaymentName
      ...ProjectBillingCreditCardPaymentSection
    }
    paymentMethodByUuid(paymentMethodUuid: $paymentMethodUuid) {
      id
      ...ProjectBillingCreditCardPaymentSection_PaymentMethod
    }
  }
`;

export default PayEngineCreditCardMethodNewPaymentBillingProjectJobPage;
