// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {DocumentModel} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// Components
import CustomerSignDocumentSignatureExpandedItem from './CustomerSignDocumentSignatureExpandedItem';

const DocumentSignature = Styled.View`
`;

const Cell = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Footer = Styled.View`
  flex-direction: row;
  margin-vertical: 30px;
  margin-horizontal: 40px;
`;

const Text = Styled.H5`
  flex: 1;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const CustomerSignDocumentSignaturesList = ({
  isStepSigned,
  document,
  documentSignatures,
  refetch,
}: {
  isStepSigned: boolean;
  document: DocumentModel;
  documentSignatures: any[];
  refetch: () => void;
}) => (
  <FlatList
    listKey={'customer-sign-document-signatures-list'}
    data={documentSignatures}
    keyExtractor={(documentSignature) => documentSignature.id}
    extraData={document}
    renderItem={({item: documentSignature, index}) => (
      <DocumentSignature>
        <CustomerSignDocumentSignatureExpandedItem
          editable={!isStepSigned}
          isFirst={index === 0}
          isLast={index === documentSignatures.length - 1}
          documentSignature={documentSignature}
          document={document}
          refetch={refetch}
        />
      </DocumentSignature>
    )}
    CellRendererComponent={({
      index,
      children,
      // @ts-expect-error TS(2769): No overload matches this call.
    }: any) => <Cell index={index} children={children} />}
    ListFooterComponent={() => (
      <Footer>
        <Text>
          {isStepSigned
            ? 'This document has already been signed. View the signature below.'
            : 'A copy of this document will be sent to you shortly after. ' +
              'By signing here, you are agreeing to all items above.'}
        </Text>
      </Footer>
    )}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerSignDocumentSignaturesList.fragment = gql`
  ${CustomerSignDocumentSignatureExpandedItem.fragment}

  fragment CustomerSignDocumentSignaturesList_Document on Document {
    id
    ...CustomerSignDocumentSignatureExpandedItem_Document
  }

  fragment CustomerSignDocumentSignaturesList on DocumentSignature {
    id
    ...CustomerSignDocumentSignatureExpandedItem
  }
`;

export default CustomerSignDocumentSignaturesList;
